import styled from 'styled-components'

import { breakpoints } from '@/styles'
import { Grid } from '@/features/Renters/elements'

import BotCards from './bot-cards'
import TopCards from './top-cards'

const CardGrid = styled(Grid)`
  grid-auto-rows: auto;

  @media (max-width: ${breakpoints.card}) {
    grid-auto-rows: auto;
    > div,
    > a {
      padding: 0;
    }
  }

  @media (max-width: ${breakpoints.tabletLandscape}) {
    grid-auto-rows: auto;
  }

  @media (max-width: ${breakpoints.phoneMax}) {
    grid-auto-rows: auto;
    > div,
    > a {
      grid-row: 1 span;
    }
  }
`

const RentReport = ({ reportTexts, botLeftImages, botRightImages }) => {
  return (
    <CardGrid breakpoint={breakpoints.card}>
      <TopCards reportTexts={reportTexts} />
      <BotCards
        reportTexts={reportTexts}
        botLeftImages={botLeftImages}
        botRightImages={botRightImages}
      />
    </CardGrid>
  )
}

export default RentReport
