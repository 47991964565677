import NextLink from 'next/link'
import styled from 'styled-components'

import { breakpoints } from '@/styles'
import { Card, Description, Text } from '@/features/Renters/elements'
import BackgroundImage from '@/components/background-image'

const ImageCard = styled(Card)`
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
`

const TextWrapper = styled.div`
  padding: 80px 70px 70px;
  flex: 1;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    padding: 60px 25px 65px;
  }
`

const ImageWrapper = styled.div`
  height: 560px;

  @media screen and (max-width: ${breakpoints.card}) {
    height: auto;
  }
`

export const BotCard = ({ id, title, desc, images, ...rest }) => {
  // "images" should be an array in this order: [standard, wide, mobile]

  return (
    <ImageCard id={id} {...rest}>
      <TextWrapper>
        <Text id={`${id}-label`}>{title}</Text>
        <Description id={`${id}-desc`} maxWidth="230px">
          {desc}
        </Description>
      </TextWrapper>
      <ImageWrapper>
        <BackgroundImage
          images={images}
          breakpoints={[breakpoints.card, breakpoints.ipadMiniMax]}
          position="static"
        />
      </ImageWrapper>
    </ImageCard>
  )
}

const PlainAnchor = styled.a`
  text-decoration: none;
`

export const Link = ({ href, children, ...rest }) => (
  <NextLink href={href} passHref legacyBehavior>
    <PlainAnchor {...rest} target="_blank" rel="noopener noreferrer">
      {children}
    </PlainAnchor>
  </NextLink>
)
