import Image from 'next/image'
import styled from 'styled-components'

import { breakpoints } from '@/styles'
import { Card, Description, Text } from '@/features/Renters/elements'

import albertaImage from './images/alberta-rent-report.png'
import montrealImage from './images/montreal-rent-report.png'
import torontoImage from './images/toronto-rent-report.png'
import vancouverImage from './images/vancouver-rent-report.png'

const ImageCard = styled(Card)`
  grid-column: 1 / 2 span;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
`

const TextWrapper = styled.div`
  padding: 80px 70px 50px;
  flex: 1;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    padding: 60px 25px 50px;
  }
`

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  padding: 0 70px 70px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    pointer-events: none;
    border-radius: 8px;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    grid-template-columns: 1fr;
    padding: 0 25px 60px;
  }
`

const TopCards = ({ reportTexts }) => {
  const id = reportTexts.topId

  return (
    <ImageCard>
      <TextWrapper>
        <Text>{reportTexts.topTitle}</Text>
        <Description>{reportTexts.topDesc}</Description>
      </TextWrapper>
      <ImageGrid>
        <a
          href="https://liv.rent/blog/rent-reports/vancouver/"
          id={id}
          aria-label="View the latest Vancouver rent reports"
          target="_blank"
          rel="noreferrer noopenner"
        >
          <Image src={vancouverImage} alt="Vancouver Rent Report" />
        </a>
        <a
          href="https://liv.rent/blog/rent-reports/toronto/"
          id={id}
          aria-label="View the latest Toronto rent reports"
          target="_blank"
          rel="noreferrer noopenner"
        >
          <Image src={torontoImage} alt="Toronto Rent Report" />
        </a>
        <a
          href="https://liv.rent/blog/rent-reports/alberta/"
          id={id}
          aria-label="View the latest Alberta rent reports"
          target="_blank"
          rel="noreferrer noopenner"
        >
          <Image src={albertaImage} alt="Alberta Rent Report" />
        </a>
        <a
          href="https://liv.rent/blog/rent-reports/montreal/"
          id={id}
          aria-label="View the latest Montreal rent reports"
          target="_blank"
          rel="noreferrer noopenner"
        >
          <Image src={montrealImage} alt="Montreal Rent Report" />
        </a>
      </ImageGrid>
    </ImageCard>
  )
}

export default TopCards
