import { BotCard, Link } from './elements'

const BotCards = ({ reportTexts, botLeftImages, botRightImages }) => {
  return (
    <>
      <BotCard
        id={reportTexts.botLeftId}
        aria-labelledby={`${reportTexts.botLeftId}-label`}
        aria-describedby={`${reportTexts.botLeftId}-desc`}
        as={Link}
        href={reportTexts.botLeftLink}
        title={reportTexts.botLeftTitle}
        desc={reportTexts.botLeftDesc}
        images={botLeftImages}
      />
      <BotCard
        id={reportTexts.botRightId}
        aria-labelledby={`${reportTexts.botRightId}-label`}
        aria-describedby={`${reportTexts.botRightId}-desc`}
        as={Link}
        href={reportTexts.botRightLink}
        title={reportTexts.botRightTitle}
        desc={reportTexts.botRightDesc}
        images={botRightImages}
      />
    </>
  )
}

export default BotCards
